import * as React from "react";

import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { PageWidgetBaseProps } from "../pageWidget.types";
import { WidgetOptions } from "./";

interface DividerProps extends PageWidgetBaseProps<WidgetOptions> {
    className: string;
}
interface DividerState {
    disableWidget: boolean;
}

export class Divider extends React.PureComponent<DividerProps, DividerState> {
    constructor(props: DividerProps) {
        super(props);
        this.state = {
            disableWidget: true,
        };
    }

    public componentDidMount() {
        this.setState({ disableWidget: !isClientLoggedIn() });
    }
    public render(): JSX.Element | null {
        const { className, options } = this.props;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        if (options.useStyled) {
            return (
                <div className={`divider-styled ${className} ${hideWidget}`}>
                    <div className="shape-divider--stick-bottom" id={className}>
                        <div className="shape__container">
                            <div className="shape">
                                <svg width="100%" height="130px">
                                    <defs>
                                        <pattern
                                            id={className}
                                            preserveAspectRatio="none"
                                            // style="background-repeat: none;"
                                            patternUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="100%"
                                            height="1300px"
                                            viewBox="0 0 100 1300"
                                        >
                                            <polygon fill="#ffffff" points="100,0 100,130 0,130 " />
                                        </pattern>
                                    </defs>
                                    <rect x="0" y="0" width="100%" height="130px" fill="#f8f8f8" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <div className={`divider--normal ${className} ${hideWidget}`} />;
    }
}
