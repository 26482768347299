import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget } from "../../";

import { Divider } from "./Divider";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: any[];
    useStyled: boolean;
}

const TARGETS = ["divider", "divider-model"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "divider-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetDivider, "dividerWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetDivider, "dividerWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetDivider, "general"),
                    properties: [
                        [
                            {
                                variable: "useStyled",
                                label: getI18nLocaleObject(namespaceList.widgetDivider, "useStyled"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetDivider, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const dividerWidget: PageWidgetSpec<WidgetOptions> = {
    id: "divider",
    type: "page",
    widgetGroup: WidgetGroup.LAYOUT,
    name: getI18nLocaleObject(namespaceList.widgetDivider, "dividerWidget"),
    description: getI18nLocaleObject(namespaceList.widgetDivider, "dividerWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        useStyled: false,
    }),
    container: false,
    async render(widget: Widget<WidgetOptions>, context) {
        const { styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        return <Divider className={className} options={widget.options} />;
    },
};
